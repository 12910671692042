<template>
  <div>
    <CWrapper>
      <TheHeader />
      <div class="row" style="margin-top: 10rem">
        <div class="col-sm-12">
          <h5 class="mt-5 mt-lg-4 ml-4 text-dark font-style">
            History Details
          </h5>
        </div>
      </div>
      <!-- -------for shadow Remove form table--------- -->
      <div class="row m-lg-4 text-center shadow rounded p-lg-3">
        <div class="col-lg-12">
          <div>
            <div class="table-responsive">
              <table class="table project-list-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Plan Name</th>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Plan Amount</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Invoice</th>
                  </tr>
                </thead>
                <tbody>
                   <p v-if="historyUser.length==0" >No Data Found </p>
                  <tr v-for="(list, index) in historyUser" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <router-link :to="'/plans/'"
                        >{{ list.plan_name }}
                      </router-link>
                    </td>

                    <td>{{ list.created_at | formatDate }}</td>
                    <td>{{ list.amount }}</td>
                    <td v-if="list.payment_status == 'failure'" class="active">
                      Fail
                    </td>
                    <td
                      v-if="list.payment_status == 'success'"
                      class="text-center"
                    >
                      <span class="badge bg-success">Success</span>
                    </td>
                    <td v-if="list.payment_status == 'success'">
                      NET1000/{{ list.invoice_number }}
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "../../containers/TheSidebar";
import TheHeader from "../../containers/TheHeader";
import TheFooter from "../../containers/TheFooter";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      historyUser: null,
      userdetect: {
        user_id12: null,
      },
    };
  },
  name: "Dashboard",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.getUserPlanHistory();
    }
  },
  methods: {
    ...mapActions(["GetPlanUserHistory"]),
    getUserPlanHistory() {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.GetPlanUserHistory(this.userdetect).then((res) => {
        this.historyUser = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.borderless {
  border: none;
}
.font-style {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.project-list-table {
  border-collapse: separate;
  border-spacing: 0 12px;
}
a {
  color: blue;
}
</style>
